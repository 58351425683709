import '@babel/runtime/regenerator'
import Vue from 'vue'
import VueHtml2Canvas from 'vue-html2canvas'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import components from './components'
import utils from './utils'
import './styles/main.scss'
import { countProductPrice, getOrderTotal } from '@/utils/order'
import { formatDecimal, stepPrice } from '@/utils/stepPrice'
import 'vue-scroll-picker/dist/style.css'

Vue.config.productionTip = false
Vue.prototype.$countProductPrice = countProductPrice
Vue.prototype.$getOrderTotal = getOrderTotal
Vue.prototype.$stepPrice = stepPrice
Vue.prototype.$isInt = stepPrice
Vue.prototype.$formatDecimal = formatDecimal
Vue.prototype.$env = process.env
Vue.use(components)
Vue.use(utils)
Vue.use(VueHtml2Canvas)

window.$devLog = (...args) => {
	if (process.env.NODE_ENV === 'development') {
		const log = console[args[0]]
		if (log) {
			log(...args.slice(1))
		} else {
			console.log(...args)
		}
	}
}

window.$jsonLog = (...args) => {
	if (process.env.NODE_ENV === 'development') {
		const formattedArgs = args.map(arg =>
			typeof arg === 'object' ? JSON.stringify(arg, null, 2) : arg,
		)

		const log = console[formattedArgs[0]]
		if (log) {
			log(...formattedArgs.slice(1))
		} else {
			console.log(...formattedArgs)
		}
	}
}

router.beforeEach((to, from, next) => {
	const token = store.state.storage.token
	const { fullPath: fromFullPath } = from
	const { fullPath: toFullPath } = to
	store.commit('setFromRoutePath', fromFullPath)
	if (token) {
		if (toFullPath !== '/login') {
			if (fromFullPath === '/login') {
				next()
			} else if (to.meta.roles.length > 0) {
				to.meta.roles.some(i => {
					if (i === store.state.storage.switchRole) {
						next()
					}
				})
			} else {
				next()
			}
		} else {
			next(fromFullPath)
		}
	} else {
		if (toFullPath !== '/login') {
			next('/login')
		} else {
			next()
		}
	}
})

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App),
}).$mount('#app')
