import service from './service'
// 自動折扣
export default {

	// 取得簡易菜單
	getMealList: params => service.get(`back/automaticDiscount/mealList`, { params }),

	// 新增
	add: data => service.post(`back/automaticDiscount/add`, data),

	// 檢查選擇商品
	checkItem: data => service.post(`back/automaticDiscount/checkItem`, data),

	// 取得列表
	getList: storeId => service.get(`back/automaticDiscount/getList?storeId=${storeId}`),

	// 取得開啟中的列表
	getActiveList: storeId => service.get(`back/automaticDiscount/getActiveList?storeId=${storeId}`),

	// 修改開啟狀態
	updateStatus: params => service.put(`back/automaticDiscount/updateStatus`, params),

	// 排序
	sort: params => service.put(`back/automaticDiscount/updateSort`, params),

	// 編輯
	update: data => service.put(`back/automaticDiscount/update`, data),

	// 刪除
	delete: params => service.delete(`back/automaticDiscount/delete`, { params }),
}
