import { render, staticRenderFns } from "./LoadingOverLay.vue?vue&type=template&id=064b8be8&scoped=true"
import script from "./LoadingOverLay.vue?vue&type=script&lang=js"
export * from "./LoadingOverLay.vue?vue&type=script&lang=js"
import style0 from "./LoadingOverLay.vue?vue&type=style&index=0&id=064b8be8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "064b8be8",
  null
  
)

export default component.exports