<template>

	<div v-if="isLoading" class="overlay">
		<div class="loader"></div>
	</div>

</template>

<script>

export default {
	name: 'LoadingOverLay',
	components: {},
	props: {
		isLoading: Boolean,
	},
	data() {
		return {
			// 用來存 setTimeout ID
			loadingTimeoutId: null,
		}
	},
	model: {
		prop: 'isLoading',
		event: 'timeoutReached',
	},
	watch: {
		// 加載超過 15 秒 發送通知
		isLoading(val) {
			if (val) {
				this.loadingTimeoutId = setTimeout(() => {
					if (this.isLoading) {
						this.$emit('timeoutReached')
					}
				}, 15000)
			} else {
				clearTimeout(this.loadingTimeoutId)
				this.loadingTimeoutId = null
			}
		},
	},
	methods: {},
}
</script>

<style scoped lang="scss">

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
	pointer-events: all; /* 這行確保所有點擊都被擋住 */
}

/* 禁止滾動 */
body.loading {
	overflow: hidden;
}

/* 旋轉加載動畫 */
.loader {
	width: 50px;
	height: 50px;
	border: 5px solid #fff;
	border-top-color: transparent;
	border-radius: 50%;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
</style>