import rootHttp from '../utils/service/root'
import storeHttp from '../utils/service/store'
import router from '../router'
import { isWebview } from '@/utils/storeId'

function onPlayAudio(state, audioId, isRepeat = false) {
	if (!window.ReactNativeWebView?.postMessage) {
		const newOrderAudio = document.getElementById(audioId)
		if (newOrderAudio.muted) {
			newOrderAudio.muted = false
		}

		state.noticeAudioFirstPlay = !isRepeat

		if (newOrderAudio.currentTime !== 0) newOrderAudio.currentTime = 0

		try {
			newOrderAudio.play()
		} catch (err) {}
	} else {
		window.ReactNativeWebView?.postMessage(
			JSON.stringify({
				type: 'sound',
				data: audioId,
			}),
		)
	}
}

export default {
	// modules: {}
	state: {
		messageText: '',
		messageColor: 'success',
		isMessage: false,
		fromRoutePath: '/',
		isShowGoFront: false,
		logoutLoading: false,
		noticeAudioFirstPlay: false,
		loading: false,
		version: process.env.VUE_APP_VERSION,
		validManagerCodeDisplay: false, // 管理者識別碼彈窗
		orderManagerRedTag: false, // 訂單管理紅點標示
		tableMode: false, //訂單管理卡片/報表模式
		posCart: {
			cart: [], // 購物車品項
			remark: '', //備註品項
			isInside: false, //是否為內用
			orderInside: '', //內用桌號
			reserve: null, //預約參數
		}, // POS 購物車緩存
		menuCategoryId: 0, // 菜單管理 分類選擇暫存

		refreshMenu: false, // 刷新店家菜單
		refreshSetting: false, // 刷新店家設定
		menuScrollPosition: 0, //菜單tab滾動位置
		maintenance: {
			modal: false, // 彈窗
			title: '', //標題
			message: '', //訊息
		},
		networkDisconnect: {
			modal: false, // 彈窗
			manuallyLoading: false, // 手動重試
		},
	},
	mutations: {
		playNoticeAudio(state) {
			if (state.noticeAudioFirstPlay) this.onPlayNoticeAudio(true)
		},
		onPlayNoticeAudio(state, isRepeat = false) {
			onPlayAudio(state, 'new-order-audio', isRepeat)
		},
		onPlayBookingOrderNoticeAudio(state, isRepeat = false) {
			onPlayAudio(state, 'booking-order-notice', isRepeat)
		},
		onPlayIdleOrderNoticeAudio(state, isRepeat = false) {
			onPlayAudio(state, 'idle-order-notice', isRepeat)
		},
		setLoading($, data) {
			$.loading = data
		},
		showMessage($, options) {
			$.isMessage = false
			if (typeof options === 'string') {
				$.messageText = options
				$.messageColor = 'success'
				$.messageDuration = 1500
			} else {
				$.messageText = options.text
				$.messageColor = options.color || 'success'
				$.messageDuration = options.duration || 1500
			}
			requestAnimationFrame(() => {
				$.isMessage = true
			})
		},
		hideMessage($) {
			$.isMessage = false
		},
		setFromRoutePath($, path) {
			$.fromRoutePath = path
		},
		setIsShowGoFront($, data) {
			$.isShowGoFront = data
		},
		setLogoutLoading($, data) {
			$.logoutLoading = data
		},
		setValidManagerCodeDisplay($, val) {
			$.validManagerCodeDisplay = val
		},
		setOrderManagerRedTag($, val) {
			$.orderManagerRedTag = val
		},
		setPosCart($, val) {
			$.posCart = val
		},
		setMenuCategoryId($, val) {
			$.menuCategoryId = val
		},
		setRefreshMenu($, val) {
			$.refreshMenu = val
		},
		setRefreshSetting($, val) {
			$.refreshSetting = val
		},
		setMenuScrollPosition($, val) {
			$.menuScrollPosition = val
		},
		setMaintenance($, val) {
			$.maintenance = val
		},
		setNetworkDisconnect($, val) {
			$.networkDisconnect = val
		},
		async clearAuth() {
			this.commit('storage/setToken', false)
			this.commit('storage/setXsrfToken', '')
			this.commit('storage/setUserId', 0)
			this.commit('storage/setStoreId', null)
			this.commit('storage/setStoreName', '')
			document.title = '點溡點餐平台'
			this.commit('setIsShowGoFront', false)
			this.commit('storage/setPermissions', [])
			this.commit('storage/setLoginRole', '')
			this.commit('storage/setAuthority', '')
			this.commit('storage/setSwitchRole', '')
			this.commit('storage/setSelectedMenu', '')
			this.commit('storage/setLoginStoreId', '')
			this.commit('storage/setSuper', false)
			this.commit('storage/setBuyerType', null)
			this.commit('storage/setAccount', null)
			this.commit('storage/setHasPos', false)
			this.commit('storage/setHasTag', false)
			this.commit('storage/setPosMac', '')
			this.commit('storage/setPosMacSecond', '')
			this.commit('storage/setTagMac', '')
			this.commit('storage/setShopOrderStatus', false)
			this.commit('storage/setBackPosCheckoutMode', '')
			this.commit('storage/setBackPosTagMode', '')
			this.commit('storage/setScaleFont', 1)
			this.commit('storage/setPrintClient', false)
			this.commit('storage/setIdleOrderReminderSwitch', false)
			this.commit('storage/setIdleOrderReminderMinutes', 30)
			this.commit('storage/setHasPos', false)

			this.commit('setOrderManagerRedTag', false)
			this.commit('setPosCart', {
				cart: [], // 購物車品項
				remark: '', //備註品項
				isInside: false, //是否為內用
				orderInside: '', //內用桌號
				reserve: null, //預約參數
			})
			this.commit('setMenuCategoryId', 0)
			this.commit('setRefreshMenu', false)
			this.commit('setRefreshSetting', false)
			this.commit('setMenuScrollPosition', 0)

			this.commit('storage/setAnnouncementDisplayList', [])
			this.commit('storage/setFirstShowAnnouncement', true)
			await this.dispatch('socket/disconnect')
		},
	},
	actions: {
		setIsShowGoFront(ctx) {
			const storeId = ctx.state.storage.storeId
			if (storeId) {
				storeHttp
					.getStoreContractStatus({
						storeId: storeId,
					})
					.then(res => {
						ctx.commit('setIsShowGoFront', res?.status)
					})
					.catch(err => {
						ctx.commit('showMessage', {
							text: err,
							color: 'error',
						})
					})
			}
		},
		logout(ctx, showLogoutMessage = true) {
			if (ctx.state.logoutLoading) return

			ctx.commit('setLogoutLoading', true)

			if (isWebview) {
				window.ReactNativeWebView?.postMessage(
					JSON.stringify({
						type: 'logout',
					}),
				)
			}

			rootHttp.logout().finally(() => {
				ctx.commit('setLogoutLoading', false)
				ctx.commit('clearAuth')

				if ('/login' !== router.currentRoute.path) {
					router.push('/login').then(() => {
						if (showLogoutMessage) {
							this.commit('showMessage', '登出成功')
						}
					})
				}
			})
		},
	},
}
